import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { ContactSection } from '../components/contact-section';
import { Hero } from '../components/hero';
import { Layout } from '../components/layout';
import { SEO } from '../components/seo';
import { SideBySide } from '../components/side-by-side';
import { ButtonLink } from '../components/ui/button-link';

function OutdoorEnvironmentPage(): React.ReactElement {
  return (
    <>
      <SEO title="Outdoor Environment Page" />
      <Layout>
        <Hero
          image={
            <StaticImage
              src="../images/outdoor-hero.jpg"
              alt=""
              className="flex-1 object-cover"
            />
          }
          heading={
            <h1 className="text-6xl">
              <span className="font-semibold">
                Quality Early <br />
                Childhood Education <br />
              </span>
              in Lake Cathie
            </h1>
          }
          cta={
            <ButtonLink variant="teal" to="#contact">
              Enquire Today
            </ButtonLink>
          }
        />
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <ContactSection />
      </Layout>
    </>
  );
}

function SectionOne(): React.ReactElement {
  return (
    <div className="py-12">
      <SideBySide>
        <SideBySide.ThreeCols>
          <h2 className="text-6xl">
            Learning in the
            <br />
            <span className="font-semibold">Outdoor Environment</span>
          </h2>

          <div className="mt-5 prose">
            <p>
              At Cowarra Park Preschool and Long Day Care we value the
              importance of the outdoor environment. We are surrounded by
              nature, from the kangaroos jumping alongside the road to the vast
              bush-land surrounding us. Our extensive outdoor environment is
              thoughtfully designed on one-acre of land and encourages
              children's exploration, curiosity and engagement.
            </p>
            <p>
              Our extensive outdoor environment provides the opportunity to
              interact with the natural elements - dirt, sand, water, grass,
              boulders and timber logs to name a few. Engagement with natural
              elements such as these provokes children's curiosity, invites
              exploration and interaction, stimulates thinking and spontaneity;
              and encourages risk taking and problem solving.
            </p>
          </div>
        </SideBySide.ThreeCols>

        <SideBySide.TwoCols>
          <StaticImage
            src="../images/outdoor-one.png"
            alt=""
            className="w-[20rem] md:w-auto h-auto mx-auto"
          />
        </SideBySide.TwoCols>
      </SideBySide>
    </div>
  );
}

function SectionTwo(): React.ReactElement {
  return (
    <div className="py-12">
      <SideBySide>
        <SideBySide.TwoCols>
          <StaticImage
            src="../images/outdoor-two.png"
            alt=""
            className="w-[20rem] md:w-auto h-auto mx-auto"
          />
        </SideBySide.TwoCols>

        <SideBySide.ThreeCols>
          <div className="mt-5 prose">
            <p>
              In the outdoor environment, our professional team of educators
              further support children's learning as they interact, build
              relationships and challenge children in their learning and
              exploration.
            </p>
            <p>
              Children are provided with opportunities to engage with the
              natural environment and are encouraged to develop an appreciation
              and respect for nature. We invite children to play an active role
              in sustainable practices including gardening, recycles, composting
              and the conservation of water.
            </p>
          </div>
        </SideBySide.ThreeCols>
      </SideBySide>
    </div>
  );
}

function SectionThree(): React.ReactElement {
  return (
    <div className="py-12">
      <SideBySide>
        <SideBySide.ThreeCols>
          <h2 className="text-6xl">
            Respecting and
            <br />
            <span className="font-semibold">Appreciating</span>
          </h2>
          <div className="mt-5 prose">
            <p>
              A culture of respect and appreciation of the natural environment
              is encouraged at Cowarra Park Preschool and Long Day Care, through
              children playing an active role in:
            </p>
            <ul>
              <li>Recycling and composting</li>
              <li>Water conservation</li>
              <li>Interactions with the natural environment</li>
              <li>Building curiosity in natural elements</li>
            </ul>
          </div>
        </SideBySide.ThreeCols>

        <SideBySide.TwoCols>
          <StaticImage
            src="../images/outdoor-three.png"
            alt=""
            className="w-[17rem] md:w-auto h-auto mx-auto"
          />
        </SideBySide.TwoCols>
      </SideBySide>
    </div>
  );
}

export default OutdoorEnvironmentPage;
